import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  ProfileOutlined,
  BarChartOutlined,
  SearchOutlined,
  ShopOutlined,
  DesktopOutlined,
  BellOutlined,
  IdcardOutlined,
  CopyrightOutlined,
  GiftOutlined,
  FundProjectionScreenOutlined,
  ScheduleOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user/index" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "대시보드",
    },
    children: [
      {
        exact: true,
        path: "/count",
        sidebar: {
          label: "카운트 대시보드",
        },
        component: loadable(() => import("../pages/dashboard/CountList")),
      },
      {
        exact: true,
        path: "/chart",
        sidebar: {
          label: "차트 대시보드",
        },
        component: loadable(() => import("../pages/dashboard/ChartList")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "/option",
        sidebar: {
          label: "옵션 관리",
        },
        component: loadable(() => import("../pages/product/option/List")),
      },
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "상품 관리",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/product/Detail")),
      },
      {
        exact: true,
        path: "/discount/index",
        sidebar: {
          label: "정기권 할인 관리",
        },
        component: loadable(() => import("../pages/product/discount/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/discount/register",
        component: loadable(() => import("../pages/product/discount/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/discount/detail/:id",
        component: loadable(() => import("../pages/product/discount/Detail")),
      },
      {
        exact: true,
        path: "/cafe/category",
        sidebar: {
          label: "포스 카테고리 관리",
        },
        component: loadable(() => import("../pages/product/cafeCategory/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/category/register",
        component: loadable(() => import("../pages/product/cafeCategory/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/category/detail/:id",
        component: loadable(() => import("../pages/product/cafeCategory/Modify")),
      },
      {
        exact: true,
        path: "/cafe",
        sidebar: {
          label: "포스 상품 관리",
        },
        component: loadable(() => import("../pages/product/cafe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/register",
        component: loadable(() => import("../pages/product/cafe/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/cafe/detail/:id",
        component: loadable(() => import("../pages/product/cafe/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/detailing",
    sidebar: {
      icon: <SearchOutlined />,
      label: "디테일링 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "디테일링 리포트 리스트",
        },
        component: loadable(() => import("../pages/detailing/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/detailing/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/:id",
        component: loadable(() => import("../pages/detailing/RportList")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <ScheduleOutlined />,
      label: "주문/예약/견적 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "주문내역 리스트",
        },
        component: loadable(() => import("../pages/order/NewList")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
      {
        exact: true,
        path: "/pos",
        sidebar: {
          label: "포스 주문 리스트",
        },
        component: loadable(() => import("../pages/order/pos/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/pos/detail/:id",
        component: loadable(() => import("../pages/order/pos/Detail")),
      },
      {
        exact: true,
        path: "/estimate",
        sidebar: {
          label: "견적내역 리스트",
        },
        component: loadable(() => import("../pages/order/estimate/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/estimate/detail/:id",
        component: loadable(() => import("../pages/order/estimate/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/:id",
        component: loadable(() => import("../pages/detailing/RportList")),
      },
      {
        exact: true,
        path: "/reservation",
        sidebar: {
          label: "예약내역 리스트",
        },
        component: loadable(() => import("../pages/order/reservation/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/reservation/detail/:id",
        component: loadable(() => import("../pages/order/reservation/Detail")),
      },
      {
        exact: true,
        path: "/reservation/setting",
        sidebar: {
          label: "예약관리",
        },
        component: loadable(() => import("../pages/reservationSetting/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/stats",
    sidebar: {
      icon: <FundProjectionScreenOutlined />,
      label: '통계/매출',
    },
    children: [
      {
        exact: true,
        path: "/user/analysis",
        sidebar: {
          label: "회원 분석",
        },
        component: loadable(() => import("../pages/stats/UserAnalysisList")),
      },
      {
        exact: true,
        path: "/sales",
        sidebar: {
          label: "매출 통계",
        },
        component: loadable(() => import("../pages/stats/SalesList")),
      },
      {
        exact: true,
        path: "/payment/method",
        sidebar: {
          label: "결제수단 순위",
        },
        component: loadable(() => import("../pages/stats/PaymentMethodList")),
      },
      {
        exact: true,
        path: "/product",
        sidebar: {
          label: "상품 순위",
        },
        component: loadable(() => import("../pages/stats/ProductList")),
      },
      {
        exact: true,
        path: "/reservation",
        sidebar: {
          label: "이용권 통계",
        },
        component: loadable(() => import("../pages/stats/ReservationList")),
      },
      {
        exact: true,
        path: "/user",
        sidebar: {
          label: "회원 통계",
        },
        component: loadable(() => import("../pages/stats/UserList")),
      },
    ],
  },
  {
    exact: true,
    path: "/bonus",
    sidebar: {
      icon: <FundProjectionScreenOutlined />,
      label: '추천/선물 관리',
    },
    children: [
      {
        exact: true,
        path: "/join/reward",
        sidebar: {
          label: "회원가입 리워드 설정",
        },
        component: loadable(() => import("../pages/join/Reward")),
      },
      {
        exact: true,
        path: "/recommend/reward",
        sidebar: {
          label: "친구추천 리워드 설정",
        },
        component: loadable(() => import("../pages/recommend/Reward")),
      },
      {
        exact: true,
        path: "/recommend",
        sidebar: {
          label: "친구추천 내역",
        },
        component: loadable(() => import("../pages/recommend/RewardList")),
      },
      {
        exact: true,
        path: "/gift",
        sidebar: {
          label: "선물하기 내역",
        },
        component: loadable(() => import("../pages/gift/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/preference",
    sidebar: {
      icon: <ProfileOutlined />,
      label: '취향분석 관리',
    },
    children: [
      {
        exact: true,
        path: "/questionnaire",
        sidebar: {
          label: "취향분석 질문지 리스트",
        },
        component: loadable(() => import("../pages/preference/questionnaire/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/questionnaire/detail/:id",
        component: loadable(() => import("../pages/preference/questionnaire/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/questionnaire/register",
        component: loadable(() => import("../pages/preference/questionnaire/Register")),
      },
      {
        exact: true,
        path: "/result",
        sidebar: {
          label: "취향분석 결과 리스트",
        },
        component: loadable(() => import("../pages/preference/result/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/result/detail/:id",
        component: loadable(() => import("../pages/preference/result/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/result/register",
        component: loadable(() => import("../pages/preference/result/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/contents",
    sidebar: {
      icon: <DesktopOutlined />,
      label: '컨텐츠 관리',
    },
    children: [
      {
        exact: true,
        path: "/dentRepair",
        sidebar: {
          label: "덴트 리페어 소개 관리",
        },
        component: loadable(() => import("../pages/contents/dent_repair/List")),
      },
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항 리스트",
        },
        component: loadable(() => import("../pages/contents/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/register",
        component: loadable(() => import("../pages/contents/notice/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/detail/:id",
        component: loadable(() => import("../pages/contents/notice/Modify")),
      },
      {
        exact: true,
        path: "/event",
        sidebar: {
          label: "이벤트 리스트",
        },
        component: loadable(() => import("../pages/contents/event/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/register",
        component: loadable(() => import("../pages/contents/event/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/detail/:id",
        component: loadable(() => import("../pages/contents/event/Modify")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 리스트",
        },
        component: loadable(() => import("../pages/contents/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/contents/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/detail/:id",
        component: loadable(() => import("../pages/contents/faq/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <GiftOutlined />,
      label: '쿠폰 관리',
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "쿠폰 리스트",
        },
        component: loadable(() => import("../pages/coupon/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/coupon/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail_/:id",
        component: loadable(() => import("../pages/coupon/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/coupon/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/qrTicket",
    sidebar: {
      icon: <IdcardOutlined />,
      label: 'QR 카워시 이용권 관리',
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "QR 카워시 이용권 리스트",
        },
        component: loadable(() => import("../pages/qrTicket/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/qrTicket/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/qrTicket/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/cash",
    sidebar: {
      icon: <CopyrightOutlined />,
      label: '캐시 관리',
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "캐시내역 리스트",
        },
        component: loadable(() => import("../pages/cash/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/cash/Register")),
      },
      {
        exact: true,
        path: "/refund",
        sidebar: {
          label: "환불내역 리스트",
        },
        component: loadable(() => import("../pages/cash/RefundList")),
      },
    ],
  },
  {
    exact: true,
    path: "/alarm",
    sidebar: {
      icon: <BellOutlined />,
      label: '알림 관리',
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "PUSH 리스트",
        },
        component: loadable(() => import("../pages/alarm/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/alarm/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <IdcardOutlined />,
      label: '관리자 관리',
    },
    children: [
      {
        exact: true,
        path: "/grade",
        sidebar: {
          label: "관리자 레벨 관리",
        },
        component: loadable(() => import("../pages/admin/grade/List")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "관리자 리스트",
        },
        component: loadable(() => import("../pages/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/admin/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/etc",
    sidebar: {
      icon: <SettingOutlined />,
      label: '기타 관리',
    },
    children: [
      {
        exact: true,
        path: "/time/config",
        sidebar: {
          label: "영업시간 관리",
        },
        component: loadable(() => import("../pages/etc/TimeConfig")),
      },
      {
        exact: true,
        path: "/detailing/config",
        sidebar: {
          label: "디테일링 예약대수 관리",
        },
        component: loadable(() => import("../pages/etc/DetailingConfigList")),
      },
      {
        exact: true,
        path: "/term",
        sidebar: {
          label: "약관 관리",
        },
        component: loadable(() => import("../pages/etc/term/List")),
      },
      {
        exact: true,
        path: "/grade",
        sidebar: {
          label: "회원 등급 관리",
        },
        component: loadable(() => import("../pages/etc/grade/List")),
      },
      {
        exact: true,
        path: "/waiting",
        sidebar: {
          label: "대기열 관리",
        },
        component: loadable(() => import("../pages/etc/waiting/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/grade/register",
        component: loadable(() => import("../pages/etc/grade/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/grade/detail/:id",
        component: loadable(() => import("../pages/etc/grade/Modify")),
      },
      {
        exact: true,
        path: "/raw",
        sidebar: {
          label: "Raw 데이터 다운로드",
        },
        component: loadable(() => import("../pages/etc/Raw")),
      },
    ],
  },
];
